.main {
    row-gap: var(--spacing);
}

.titlebar {
    background-image: var(--image-gradient-heading);
    border-radius: 0.2rem;
    display: flex;
    font-size: 1.2em;
    height: 2.2em;
}

.titlebar.link {
    cursor: pointer;
}

.title {
    flex-grow: 1;
    padding: var(--spacing);
}

.info {
    align-items: center;
    display: flex;
    font-size: small;
    margin: 0 var(--spacing);
}

.button {
    display: flex;
}

.button img {
    padding: calc(var(--spacing)) calc(var(--spacing) / 3);
    height: 100%;
    width: 100%;
}

.content {
    margin-bottom: var(--spacing);
}

.empty {
    margin-bottom: var(--spacing);
}