.main {
    line-height: 1.8em;
    padding: var(--spacing) calc(2 * var(--spacing));
}

.main h1,
.main h2,
.main h3,
.main h4,
.main h5,
.main p {
    font-size: 1em;
    padding: 0 0 var(--spacing) 0;
    margin: 0;
}

.main h2 {
    font-weight: normal;
    text-decoration: underline;
}

.main p,
.main li {
    hyphens: auto;
    text-align: left;
}

.main pre {
    background-color: var(--color-bg-highlight);
    padding: var(--spacing);
}