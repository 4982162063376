.icon {
    cursor: pointer;
    filter: var(--filter-icon);
    padding: calc(var(--spacing) / 4);
}

.label:hover {
    transform: scale(1.2);
}

.gray {
    opacity: 0.3;
}

.gray:hover {
    opacity: 1.0;
}

.label {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 0 calc(var(--spacing));
    padding: 0 calc(var(--spacing));
}