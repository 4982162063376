.main {
    column-gap: calc(var(--spacing) / 4);
    cursor: text;
    font-size: 1em;
}

.main:hover {
    background-color: var(--color-bg-highlight);
}

.bits {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    column-gap: var(--spacing);
    font-size: x-small;
    padding: calc(var(--spacing) / 2) var(--spacing);
}

.bits .bit {
    background-color: var(--color-bg-highlight);
    display: inline-block;
    font-size: x-small;
    line-height: 1em;
    padding: calc(var(--spacing) / 2);
}

.button {
    display: flex;
}

.strikethru .data {
    text-decoration: line-through;
}

.data {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    font-size: 1em;
    flex-grow: 1;
    line-height: 1.6em;
    padding: calc(var(--spacing) / 2) var(--spacing);
}

textarea.data.oneline,
div.data.oneline {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.data.oneline {
    display: inline-flex;
    align-items: stretch;
}

.data.readonly {
    cursor: default;
    user-select: none;
}

.data.readonly.link {
    cursor: pointer;
}

.data * {
    font-size: 1em;
    margin: 0;
}

.data:not(.oneline) h1 {
    font-weight: bold;
}

.data:not(.oneline) h2 {
    font-weight: normal;
    text-transform: uppercase;
}

.data:not(.oneline) h3 {
    font-weight: normal;
    font-style: italic;
}

.data:not(.oneline) h3 {
    font-weight: normal;
    text-decoration: underline;
    font-style: italic;
}

.data:not(.oneline) h4 {
    font-weight: bold;
    text-transform: uppercase;
}

.data:not(.oneline) h5 {
    font-weight: normal;
    text-decoration: underline;
    text-transform: uppercase;
}

.data:not(.oneline) h6 {
    font-weight: normal;
    text-decoration: underline;
    font-style: italic;
    text-transform: uppercase;
}

.data:not(.oneline) h1:not(:first-child),
.data:not(.oneline) h2:not(:first-child),
.data:not(.oneline) h3:not(:first-child),
.data:not(.oneline) h4:not(:first-child),
.data:not(.oneline) h5:not(:first-child),
.data:not(.oneline) h6:not(:first-child),
.data:not(.oneline) p:not(:first-child),
.data:not(.oneline) hr:not(:first-child),
.data:not(.oneline) blockquote:not(:first-child),
.data:not(.oneline) pre:not(:first-child),
.data:not(.oneline) code:not(:first-child) {
    margin-top: calc(var(--spacing) * 2);
    margin-bottom: calc(var(--spacing) * 2);
}

.data.oneline h1,
.data.oneline h2,
.data.oneline h3,
.data.oneline h4,
.data.oneline h5,
.data.oneline h6,
.data.oneline ul,
.data.oneline ol,
.data.oneline li,
.data.oneline p,
.data.oneline blockquote,
.data.oneline pre,
.data.oneline code {
    display: inline;
    font-weight: normal;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.data.oneline p+p:before,
.data.oneline li+li:before {
    content: "□ ";
}

.data.oneline {
    h1 ~ * {
        display: none;
    }
}

.name {
    flex: 1;
}