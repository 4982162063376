.main {
    align-items: center;
    background-color: transparent;
    border: var(--width-line) solid transparent;
    display: flex;
    padding: var(--spacing) calc(var(--spacing) / 1.5);
}

.main.active,
.main:hover,
.main:active {
    border-color: var(--color-line-3);
    border-bottom-color: var(--color-bg-text);
    z-index: 100;
}

.main::after {
    content: "\200b";
}

.main .icon {
    filter: var(--filter-icon);
    height: var(--width-icon);
    width: var(--width-icon);
}

.main .name {
    display: none;
    padding: 0 calc(var(--spacing) / 1.5);
}

.main.active .name {
    display: none;
    font-weight: bold;
}

.main:hover .name {
    display: none;
}

a.main,
a.main:link,
a.main:visited,
a.main:focus,
a.main:active {
    color: var(--color-fg-text);
    outline: none;
    text-decoration: none;
}

a.main:hover,
a.main:active {
    background-color: var(--color-bg-text);
}

a.main:hover.active,
a.main:active.active {
    background-color: transparent;
}