.group {
    font-size: 1em;
    padding: var(--spacing);
    row-gap: calc(var(--spacing) / 2);
}

.group>.label {
    border-top: var(--width-line) solid var(--color-line-2);
    font-family: var(--font-decorative);
    font-style: italic;
    padding: calc(var(--spacing) * 2) 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main .label {
    padding: var(--spacing);
    min-width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main .optionGroup {
    display: flex;
    align-items: center;
    column-gap: calc(var(--spacing) * 2);
    justify-content: stretch;
    width: 100%;
}

.main .option {
    cursor: pointer;
    display: block;
}

.main input {
    width: inherit;
    flex-grow: 1;
    color-scheme: var(--color-scheme);
}

.main .bullet {
    padding: var(--spacing);
}

.main .link {
    cursor: pointer;
    align-items: center;
    display: flex;
    flex-grow: 1;
    white-space: nowrap;
}

.link,
.button {
    justify-content: center;
    display: inline-flex;
    column-gap: var(--spacing);
}

.link .shortcuts {
    margin-left: auto;
}

.link .shortcuts kbd {
    cursor: pointer;
    margin: calc(var(--spacing) / 4);
}

.button {
    cursor: pointer;
    margin: var(--spacing) 0;
}